<template>
  <div v-if="getItemError">
    <b-alert
      variant="danger"
      show
    >
      <h4 class="alert-heading">
        Ошибка при получении данных о компании
      </h4>
      <div class="alert-body">
        Не найдено ни одной компании с этим идентификатором.
      </div>
    </b-alert>
  </div>
  <b-overlay
    v-else
    :show="loading"
    spinner-variant="primary"
  >
    <b-card>
      <div class="">
        <b-row v-if="error_message">
          <b-col>
            <small
              class="text-danger d-block mb-1"
            >
              {{ error_message }}
            </small>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label="Название"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model.trim="item.name"
                placeholder="Введите название компании"
                :state="get(validation, 'name[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'name[0]')"
                class="text-danger"
              >
                {{ get(validation, 'name[0]') }}
              </small>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Дата создания"
              label-for="item-created-at"
            >
              <b-form-input
                id="item-created-at"
                plaintext
                :value="item.created_at"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Дата изменения"
              label-for="item-updated-at"
            >
              <b-form-input
                id="item-updated-at"
                plaintext
                :value="item.updated_at"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col>
            <b-button
              variant="primary"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="updateItem"
            >
              Сохранить
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BAlert, BCard, BRow, BCol, BFormGroup, BFormInput, BButton, BOverlay,
} from 'bootstrap-vue'
import { get } from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getCompany, updateCompany } from '@/services/main-api/business/companies'

export default {
  name: 'EditCompanyView',
  components: {
    BAlert,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BOverlay,
  },
  data() {
    return {
      item: {},
      validation: {},
      error_message: '',
      loading: false,
      getItemError: false,
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      this.loading = true
      await this.getItem()
      this.loading = false
    },
    async getItem() {
      try {
        const res = await getCompany(this.$store.getters['workingMode/selected_company_id'])
        this.item = res.data.data
      } catch (e) {
        this.item = {}
        this.getItemError = true
      }
    },
    async updateItem() {
      this.clearValidation()
      this.loading = true
      try {
        await updateCompany({
          id: this.item.id,
          name: this.item.name,
        })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Изменено!',
            icon: 'BellIcon',
            text: 'Компания была изменена.',
            variant: 'success',
          },
        })
        this.clearForm()
        this.$store.dispatch('workingMode/getData')
        this.$router.push({ name: 'company.show' })
      } catch (e) {
        this.validation = get(e.response, 'data.errors')
        this.error_message = get(e.response, 'data.error', '')
      } finally {
        this.loading = false
      }
    },
    get,
    clearValidation() {
      this.validation = {}
      this.error_message = ''
    },
    clearForm() {
      this.validation = {}
      this.error_message = ''
      this.item.name = ''
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
